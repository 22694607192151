<template>
  <div
    class=""
  >
    <div
      v-show="!isGantt"
      id="mapContainer"
      style="position: relative;"
    >
      <!-- iframe -->
      <iframe
        v-if="!isAerodyneDemo"
        v-show="isPointCloud"
        style="position: absolute; z-index: 1; height: 90vh; width: 100%;"
        src="https://aerodyne-prism-petronas.s3.ap-southeast-1.amazonaws.com/PETRONAS_LEADERSHIP_CENTER_%28PLC%29/04_DELIVERY/04_HOSTING/02_POTREE/PETRONASLeadershipCenter%28PLC%29SmallAreaBentley/index.html"
      />
      <iframe
        v-if="isAerodyneDemo"
        v-show="isPointCloud"
        style="position: absolute; z-index: 1; height: 90vh; width: 100%;"
        src="https://aerodyne-mco-data.s3.ap-southeast-1.amazonaws.com/Aerodyne+Campus/Processed/Potree/AerodyneCampus/index.html"
      />
      <iframe
        v-if="is3DModel"
        style="position: absolute; z-index: 1; height: 90vh; width: 100%;"
        src="https://dev.petronas.myprism.biz/mesh/3dmodel.html"
      />
      <div
        v-if="isSynchro"
        class=""
        style="position: fixed; z-index: 1; width: 92vw; height: 90vh; background-color: black;"
      >
        <div
          class=""
          style="position: absolute; color: white; width: 100%; height: 90vh; z-index: 3;"
        >
          <synchro-player :player-options="playerOptions" />
        </div>
      </div>
      <!-- <div style="position: relative; z-index: 1; height: 100vh; width: 100%;"> -->
      <iframe
        v-if="is3DCompare"
        style="position: absolute; z-index: 1; height: 100vh; width: 100%;"
        src="https://dev.petronas.myprism.biz/mesh/3dmodelComparison.html"
      />
      <!-- </div> -->

      <map-compare
        v-if="is2DCompare"
        style="position: absolute; z-index: 1; height: 90vh; width: 100%; background-color: #f8f8f8;"
        @sliderPosition="sliderPosition"
      />
      <!-- left menu -->
      <div
        v-show="isShowPanel"
        style="position: absolute; left: 0; bottom: 0; top: 0; padding: 10px; z-index: 1;"
      >
        <div
          class="card"
          style="width: 40vh; height: 100%; overflow-y: auto;"
        >
          <left-menu-location
            v-show="!isPointCloud && !is3DModel && !isSynchro"
            :bool-ortho="isOrtho"
            :bool3d="is3DModel"
            :bool-synchro="isSynchro"
            :bool-point-cloud="isPointCloud"
            :all-project="project.all"
            :hiderfid="isHideRfid"
            :hideothers="isHideOthers"
            @locationClicked="onClickChildLocation"
            @hideProj="hideProject"
            @showTimeline="showTimelines"
            @layerBackClicked="onClickLayerBack"
            @toggleSwitched="switchToggled"
            @onHistoryClick="onHistoryClick"
            @OnViewGantt="showAllTask"
            @onItemClicked="onItemClickedSideBar"
            @onItemBack="onItemBack"
            @currentProject="currentProject"
            @toggleAddMarkerNewProject="toggleAddMarkerNewProject"
            @deleteProject="apiGetAllProjects"
          />
          <mesh-info
            v-show="isPointCloud"
          />
          <tower-mesh
            v-show="is3DModel"
          />
          <simulation v-show="isSynchro" />
        </div>
      </div>

      <div
        v-show="isShowPanel"
        style="position: absolute; left: 41vh; top: 0; padding: 20px; z-index: 1;"
      >
        <div
          class="card"
          style="padding: 14px; cursor: pointer;"
          @click="togglePanel"
        >
          <div class="my-auto mx-auto">
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </div>
        </div>
      </div>
      <div
        v-show="!isShowPanel"
        style="position: absolute; left: 0; top: 0; padding: 20px; z-index: 1;"
      >
        <div
          class="card"
          style="padding: 14px; cursor: pointer;"
          @click="togglePanel"
        >
          <div class="d-flex">
            <div class="my-auto mx-auto">
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
                class="mr-1"
              />
            </div>
            <div class="my-auto mx-auto">
              Show Panel
            </div>
          </div>
        </div>
      </div>
      <!-- left ortho compare date -->
      <div
        v-show="is2DCompare"
        style="position: absolute; top: 0; padding: 20px; z-index: 1;"
        :style="`left: ${positionXLeft}`"
      >
        <div
          class="card"
          style="padding: 14px;"
        >
          <!-- <div>14/10/2021</div> -->
          <b-form-input
            id="v-planned-start"
            v-model="leftDate"
            type="date"
            size="sm"
          />
        </div>
      </div>
      <!-- right ortho compare date -->
      <div
        v-show="is2DCompare"
        style="position: absolute; top: 0; padding: 20px; z-index: 1;"
        :style="`left: ${positionXRight}`"
      >
        <div
          class="card"
          style="padding: 14px;"
        >
          <!-- <div>15/01/2022</div> -->
          <div>
            <b-form-input
              id="v-planned-end"
              v-model="rightDate"
              type="date"
              size="sm"
            />
          </div>
        </div>
      </div>
      <div
        class="card mapStyle"
        style="cursor: pointer; width: 100px; height: 100px; position: absolute; left: 43vh; bottom: 10px; z-index: 1;"
      >
        <img
          v-if="!isSatellite"
          src="../../../../assets/images/mapbox/satellite.png"
          @click="changeMapStyle()"
        >
        <img
          v-if="isSatellite"
          src="../../../../assets/images/mapbox/map.png"
          style="margin: -27px; margin-top: -20px;"
          @click="changeMapStyle()"
        >
      </div>

      <b-modal
        id="modal-table"
        hide-footer
        centered
        size="xl"
      >
        <productivity-manpowers />
      </b-modal>
      <!-- manhours modal -->
      <b-modal
        id="actual-manhours"
        hide-footer
        header-bg-variant="white"
        centered
        size="xl"
      >
        <actual-manhours-productivity />
      </b-modal>
      <b-modal
        id="zone-modal"
        v-model="showModal"
        title="Add New Data"
        hide-footer
        centered
        size="lg"
      >
        <zone-modal
          :coordinates="createdCoordinates"
          :tasks="task.all"
          :edit-zone-id="editZoneId"
          @successAddedZone="successAddedZone"
        />
      </b-modal>
      <b-modal
        id="zone-modal-edit"
        v-model="showModalEdit"
        title="Edit Zone"
        hide-footer
        centered
        size="lg"
        :no-close-on-backdrop="true"
      >
        <zone-modal-edit
          :tasks="task.all"
          :edit-zone-id="editZoneId"
          @successAddedZone="successAddedZone"
        />
      </b-modal>
      <b-modal
        id="task-edit-modal"
        v-model="showEditTaskModal"
        title="Edit"
        hide-footer
        centered
        size="lg"
        :no-close-on-backdrop="true"
      >
        <task-modal
          :edit-task-id="editTaskId"
          @successAddedZone="successAddedZone"
        />
      </b-modal>
      <div
        v-show="is3DPanel"
        class="card"
        style="padding: 12px; position: absolute; right: 66px; bottom: 196px; z-index: 1;"
        :style="{'bottom': isSlider ? '196px' : '86px'}"
      >
        <div
          v-if="isOrtho"
          class="d-flex justify-content-between mb-1"
        >
          <div class="my-auto mr-3">
            Opacity
          </div>
          <input
            id="orthoOpacity"
            v-model="orthoOpacity"
            type="range"
            min="0"
            max="100"
            style="cursor: ew-resize"
          >
          <div class="ml-1">
            {{ orthoOpacity }} %
          </div>
        </div>
        <div class="d-flex justify-content-between mb-1">
          <div class="my-auto mr-3">
            Orthophoto
          </div>
          <b-form-checkbox
            v-model="isOrtho"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="toggleSwitch('ortho')"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
        <div class="d-flex justify-content-between mb-1">
          <div class="my-auto mr-3">
            3D Mesh
          </div>
          <b-form-checkbox
            v-model="is3DModel"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="toggleSwitch('3d')"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
        <div class="d-flex justify-content-between mb-1">
          <div class="my-auto mr-3">
            Point Cloud
          </div>
          <b-form-checkbox
            v-model="isPointCloud"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="toggleSwitch('pointcloud')"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
        <div class="d-flex justify-content-between">
          <div class="my-auto mr-3">
            Synchro 4D
          </div>
          <b-form-checkbox
            v-model="isSynchro"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="toggleSwitch('synchro')"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
      </div>
      <div
        v-show="isComparePanel"
        class="card"
        style="position: absolute; right: 66px; z-index: 1;"
        :style="{'bottom': isSlider ? '178px' : '68px'}"
      >
        <div
          class="d-flex justify-content-between pointer"
          style="padding: 12px;"
          :style="{'background-color': is2DCompare ? '#f8f8f8' : '', 'border-radius': '0.428rem'}"
          @click="toggleCompare('2d')"
        >
          <div class="my-auto">
            2D
          </div>
        </div>
        <div
          class="d-flex justify-content-between pointer"
          style="padding: 12px;"
          :style="{'background-color': is3DCompare ? '#f8f8f8' : '', 'border-radius': '0.428rem'}"
          @click="toggleCompare('3d')"
        >
          <div class="my-auto">
            3D
          </div>
        </div>
      </div>
      <div
        v-if="isSlider"
        class="card"
        style="padding: 2px; width: 46px; position: absolute; right: 10px; z-index: 1;"
        :style="{'bottom': isSlider ? '130px' : '20px'}"
      >
        <div>
          <div
            id="tooltip-1"
            class="d-flex pointer"
            style="height: 46px;"
            @click="toggleBottomRightPanel('layer')"
          >
            <feather-icon
              class="mx-auto my-auto"
              icon="BoxIcon"
              size="25"
            />
          </div>
          <b-tooltip
            target="tooltip-1"
            :delay="delay"
          >
            Object Layers
          </b-tooltip>
          <div
            id="tooltip-2"
            class="d-flex pointer"
            style="height: 46px;"
            @click="toggleBottomRightPanel('compare')"
          >
            <feather-icon
              class="mx-auto my-auto"
              icon="ColumnsIcon"
              size="25"
            />
          </div>
          <b-tooltip
            target="tooltip-2"
            :delay="delay"
          >
            3D Compare
          </b-tooltip>
          <!-- <div
            id="tooltip-3"
            class="d-flex pointer"
            style="height: 46px;"
          >
            <feather-icon
              class="mx-auto my-auto"
              icon="ZoomInIcon"
              size="25"
            />
          </div>
          <b-tooltip
            target="tooltip-3"
            :delay="delay"
          >
            Zoom
          </b-tooltip> -->
          <!-- <div
            id="tooltip-4"
            class="d-flex pointer"
            style="height: 46px;"
            @click="productivityManhours()"
          >
            <feather-icon
              class="mx-auto my-auto"
              icon="BarChart2Icon"
              size="25"
            />
          </div>
          <b-tooltip
            target="tooltip-4"
            :delay="delay"
          >
            Chart
          </b-tooltip> -->
        </div>
      </div>
      <!-- slider -->
      <div
        v-if="isTimeline"
        class="card"
        style="padding: 16px; width: 550px; position: absolute; right: 10px; bottom: 10px; z-index: 1;"
      >
        <div>
          <vue-slider
            v-model="value"
            :data="data"
            :marks="marks"
            class="mb-3 ml-1 mr-1"
            :direction="direction"
            @change="sliderChange"
          />
        </div>
      </div>
    </div>
    <!-- gantt map -->
    <div
      v-show="isGantt"
      id="mapContainerGantt"
      style="position:relative; z-index: 1;"
    >
      <div style="position: absolute; width: inherit; bottom: 0; z-index: 2;">
        <div
          v-show="isSpecific"
          style="position: absolute; left: 50px; z-index: 2; width: 140px"
          class="onHover rounded bg-white p-1"
          @click="showAllTask()"
        > <feather-icon
          icon="RefreshCcwIcon"
          class="mr-75"
        /><span style="color: #5E5873; font-weight: 500">Show All Site</span></div>
        <!-- <div
          class="card"
          style="position: absolute; right: 210px;"
        >
          <div class="d-flex">
            <div class="my-auto mr-3">
              Opacity
            </div>
            <input
              id="orthoOpacity"
              v-model="orthoOpacity"
              type="range"
              min="0"
              max="100"
              style="cursor: ew-resize"
            >
            <div class="ml-1">
              {{ orthoOpacity }} %
            </div>
          </div>
        </div> -->
        <div
          class="card"
          style="position: absolute; right: 21px;"
        >
          <div class="d-flex">
            <div class="my-auto p-1">
              Opacity
            </div>
            <input
              id="orthoOpacity"
              v-model="orthoOpacity"
              type="range"
              min="0"
              max="100"
              style="cursor: ew-resize"
            >
            <div class="my-auto p-1">
              {{ orthoOpacity }} %
            </div>
            <div style="height: auto; width: 1px; background-color: lightgray;" />
            <div
              class="pointer p-1"
              @click="modalDefectIncident('defects')"
            >
              Quality Issues
            </div>
            <div style="height: auto; width: 1px; background-color: lightgray;" />
            <div
              class="pointer p-1"
              @click="modalDefectIncident('incidents')"
            >
              Incidents
            </div>
          </div>
        </div>
        <gantt-latest
          :gantt-data="currentData.gantt"
          :table-data="currentData.table"
          :title="currentTitle"
          :no-data="noData"
          class="mt-3"
          @taskClick="onClickTaskName"
          @actionClick="onClickAction"
          @backButton="onBack"
          @closeSCurve="showAllTask"
        />
      </div>
      <b-modal
        id="modal-defect-incident"
        hide-footer
        centered
        size="xl"
      >
        <defect-incident
          :type="selectedDefectIncidentType"
          :selected-project="selectedProject"
        />
      </b-modal>
    </div>
    <NewProjectModal
      ref="newProjectModal"
      :coordinate="project.newProjectCoordinate"
      @removeMarkerNewMap="removeMarkerNewMap"
      @refreshAllProject="apiGetAllProjects"
    />
  </div>
</template>
<style>
.onHover{
  transition: transform .2s;
}
.onHover:hover {
  transform: scale(1.2);
  z-index: 2;
}
#mapContainer, #mapContainerGantt {
    height: 92vh!important;
    width: 94vw !important;
}
.mapboxgl-canvas{
  width: 100%!important;
}
.noBackground{
background-color: rgb(154, 154, 154)!important;
width:"900";
            height:"700";
}
.marker {
   /* background-image: url('mapbox-icon.png'); */
  background-size: cover;
  width: 20px;
  height: 20px;
  /* border-radius: 50%; */
  /* cursor: pointer; */
}

.greendot {
  background-image: url('../../../../assets/images/icons/green-dot.png');
}

.reddot {
  background-image: url('../../../../assets/images/icons/red-dot.png');
}

.purpledot {
  background-image: url('../../../../assets/images/icons/purple-dot.png');
}

.pinkdot {
  background-image: url('../../../../assets/images/icons/pink-dot.png');
}

.yellowdot {
  background-image: url('../../../../assets/images/icons/yellow-dot.png');
}

/* .greydot {
  background-image: url('../../../../assets/images/icons/grey-dot.png');
} */

.hide {
  display: none;
}

.line-through {
  text-decoration: line-through;
}

/* .droneData:hover */
.cctv:hover, .smartHelmet:hover, .smartGlasses:hover{
  cursor: pointer;
}

.pointer:hover {
  cursor: pointer;
  background: #F8F8F8;
  border-radius: 0.428rem;
}

.mapboxgl-canvas {
  width: 100% !important;
}

/* html[dir] .navbar-hidden .app-content{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

html[dir] .content.app-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
} */

/* [dir=ltr] .container, [dir=ltr] .container-fluid, [dir=ltr] .container-xxl, [dir=ltr] .container-xl, [dir=ltr] .container-lg, [dir=ltr] .container-md, [dir=ltr] .container-sm {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
} */
</style>
<script>
import {
  BFormCheckbox, VBTooltip, BTooltip, BFormInput,
} from 'bootstrap-vue'
// import VueSlider from 'vue-slider-component'
import mapboxgl from 'mapbox-gl'
import * as THREE from 'three'
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'
import VueSlider from 'vue-slider-component'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import {
  ref,
} from '@vue/composition-api'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import leftMenuLocation from '@/views/pages/dashboard/map/Location.vue'
import ActualManhoursProductivity from '@/views/pages/dashboard/map/component/modal/ActualManhoursProductivity.vue'
import ZoneModal from '@/views/pages/dashboard/map/component/modal/ZoneModal.vue'
import ZoneModalEdit from '@/views/pages/dashboard/map/component/modal/ZoneModalEdit.vue'
import TaskModal from '@/views/pages/dashboard/map/component/modal/TaskModal.vue'
import mapCompare from '@/views/pages/dashboard/map/map-compare.vue'
import ProductivityManpowers from './component/productivityManpowers.vue'
import DefectIncident from './component/modal/DefectIncident.vue'
import GanttLatest from './component/ganttChart.vue'
import MeshInfo from './component/meshInfo.vue'
import TowerMesh from './component/towerMesh.vue'
import SynchroPlayer from './component/synchroPlayer.vue'
import Simulation from './component/simulation.vue'
// import InfoBuilding from './component/infoBuilding.vue'
// import Mesh from '@/views/pages/modelling/3dMesh.vue'
// import MeshCompare from '@/views/pages/modelling/3dCompare.vue'
// import ActualManhoursProductivity from './modal/ActualManhoursProductivity.vue'
import mapViewMixin from './Map_View'
// import rfidSelected from './component/rfidSelected.vue'
// import addFlight from './component/addFlight.vue'
import EventBus from './eventBus'
import NewProjectModal from './component/modal/AddNewProject.vue'

require('three/examples/js/loaders/GLTFLoader')

export default {
  components: {
    BFormInput,
    // BInputGroup,
    // BInputGroupAppend,
    VueSlider,
    leftMenuLocation,
    ProductivityManpowers,
    ActualManhoursProductivity,
    GanttLatest,
    BFormCheckbox,
    // Mesh,
    // MeshCompare,
    BTooltip,
    TowerMesh,
    MeshInfo,
    ZoneModal,
    ZoneModalEdit,
    TaskModal,
    // rfidSelected,
    // addFlight,
    mapCompare,
    NewProjectModal,
    DefectIncident,
    Simulation,
    SynchroPlayer,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [mapViewMixin],

  data(){
    return {
      playerOptions: null,
      publicPath: process.env.BASE_URL,
      marker: null,
      project: {
        newProjectCoordinate: {
          lat: null,
          long: null,
        },
        all: [],
      },
      manhouroverview: [],
      newArea: {
        allTask: [],
      },
      task: {
        all: [],
      },
      noData: true,
      isHideOthers: false,
      isHideRfid: false,
      selectedDefectIncidentType: null,
      taskByZones: [],
      currentProjectID: null,
      toggleCreateMarker: false,
      orthoOpacity: 100,
      editZoneId: '',
      editTaskId: '',
      allZoneList: [],
      allZoneListId: [],
      currentZoneIdList: [],
      projectTaskZones: [],
      showEditTaskModal: false,
      positionXLeft: '',
      positionXRight: '',
      leftDate: '2021-01-16',
      rightDate: '2022-02-08',
      createdCoordinates: [],
      showModal: false,
      showModalEdit: false,
      is3DCompare: false,
      is2DCompare: false,
      isSlider: false,
      isTimeline: false,
      // project: {
      //   all: [],
      // },
      selectedProject: null,
      isAerodyneDemo: false
    }
  },
  // searching for data(){} ?. It is in Map_View.js
  setup() {
    const isTaskHandlerSidebarActive = ref(false)
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const handleTaskClick = taskData => {
      // task.value = taskData
      console.log(taskData)
      isTaskHandlerSidebarActive.value = true
    }

    return {
      isTaskHandlerSidebarActive,
      handleTaskClick,
      mqShallShowLeftSidebar,
      // createdCoordinates: [],
      // showModal: false,
      // showModalEdit: false,
      // is3DCompare: false,
      // is2DCompare: false,
      // isSlider: false,
      // isTimeline: false,
      // project: {
      //   all: [],
      // },
      // selectedProject: null,
    }
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  mounted() {
    this.playerOptions = {
      sources: [
        // { src: "http://18.138.6.232/dros/vid/dosh.mp4", type: "video/mp4", withCredentials: false },
        { src: `${this.publicPath}files/vid/synchro1.mp4`, type: "video/mp4", withCredentials: false },
        { src: `${this.publicPath}files/vid/synchro2.mp4`, type: "video/mp4", withCredentials: false },
        { src: `${this.publicPath}files/vid/synchro3.mp4`, type: "video/mp4", withCredentials: false },
        { src: `${this.publicPath}files/vid/synchro4.mp4`, type: "video/mp4", withCredentials: false },
        { src: `${this.publicPath}files/vid/synchro5.mp4`, type: "video/mp4", withCredentials: false },
        { src: `${this.publicPath}files/vid/synchro6.mp4`, type: "video/mp4", withCredentials: false },
      ],
      loop: true,
      html5: { hls: { withCredentials: false } },
      autoplay: true,
      poster: "/logo.png",
      controls: false,
      overNative: true,
    }

    mapboxgl.accessToken = 'pk.eyJ1IjoicmV4emFjazkiLCJhIjoiY2sxYTF6d2QyMDRlZjNkdDlyM3Z5N24zMSJ9.U5TuDBZwdUsnwntE1h2w6w'

    this.map = new mapboxgl.Map({
      container: 'mapContainer', // container ID
      style: 'mapbox://styles/mapbox/light-v10', // style URL
      center: [101.740112, 2.962541],
      zoom: 15,
      antialias: true,
    })

    this.map.on('load', () => {
      this.apiGetAllProjects()
      // this.apiGetAllTask()
      // this.plotLocationMarker()
    })
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.dummyData = response.data
      })
    this.$http.get('/analytics/data')
      .then(response => {
        const dashboardData = response.data
        this.manhouroverview = dashboardData.manhourdataOverview
        console.log(this.manhouroverview)
      })
    // temp solution for mapbox height problem
    if (localStorage.getItem('reloaded')) {
      localStorage.removeItem('reloaded')
    } else {
      localStorage.setItem('reloaded', '1')
      this.$router.go(0)
    }
    const path = window.location.host
    if (path.indexOf("aerodyne") >= 0) this.isAerodyneDemo = true
  },
  methods: {
    hideProject(projId) {
      console.log(projId)
      const test = document.getElementsByClassName(`${projId}`)

      if (test[0].classList.contains("hide")) {
        test[0].classList.remove("hide")
      } else {
        test[0].classList += " hide"
      }

      // if (test[0].style.display === "block") {
      //   test[0].style.display = "none"
      // } else {
      //   test[0].style.display = "block"
      // }
      // this.project.all.forEach(element => {
      //   const test = document.getElementsByClassName(`${projId}`)
      //   test[0].style.display = "block"
      //   if (element.id === projId) {
      //     test[0].style.display = "none"
      //   }
      // })
    },
    sliderPosition(data) {
      console.log(data)
      if (data.isInitial) {
        this.positionXLeft = `${data.position - 226}px`
        this.positionXRight = `${data.position}px`
      } else {
        this.positionXLeft = `${data.position - 240}px`
        this.positionXRight = `${data.position}px`
      }
    },
    toggleAddMarkerNewProject(){
      this.marker = new mapboxgl.Marker()
      this.map.once('click', this.add_marker.bind(this))
      // this.map.on('click', this.add_marker.bind(this))

      const timeout = 3000
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Info`,
          icon: "CoffeeIcon",
          variant: "info",
          text: `Click on the map to add new project's location`,
        },
      }, { timeout })
    },
    add_marker(event) {
      const coordinates = event.lngLat
      console.log('Lng:', coordinates.lng, 'Lat:', coordinates.lat)
      this.project.newProjectCoordinate = coordinates
      this.marker.setLngLat(coordinates).addTo(this.map)
      this.$refs.newProjectModal.show()
    },
    removeMarkerNewMap(){
      this.marker.remove()
    },
    currentProject(data){
      this.currentTitle = data.name
      this.selectedProject = data
      this.currentProjectID = data.id
    },
    successAddedZone(){
      this.showModal = false
      this.showModalEdit = false
      this.showEditTaskModal = false
      this.showAllTask()
    },
    showAllTask(){
      console.log('show all task')
      let targetProjectId = ""
      if (!this.isAerodyneDemo) {
        targetProjectId = this.selectedProject.id
      } else {
        targetProjectId = "61ea50aceb938e4dc239228a" // use PLC site only for Aerodyne Demo
      }
      this.isSpecific = false
      this.$axios.get(`${this.$baseUrl}/tasks/getByProject/${targetProjectId}`).then(res => {
        console.log(res)
        if (res.status === 200 || res.status === 201) {
          let resData = res.data

          console.log(resData)

          if (res.data.length > 0) {
            this.currentData.table = resData
            this.task.all = resData
            this.noData = false
          } else {
            resData = this.currentData.Table
            this.noData = true
          }
          const ganttList = []
          resData.forEach(item => {
            ganttList.push({
              id: item.id,
              // site: ['1', '4', '5'],
              name: item.name,
              label: `<div style="cursor: pointer;"> ${item.name}</div>`,
              testing: '<div style="color: blue; cursor: pointer;"><i class="fa fa-pencil-alt" style="margin-right: 10px;"></i>Edit</div>',
              wbs: item.wbsCode,
              plannedStart: '23-1-2021',
              plannedEnd: '25-4-2021',
              start: item.startDate,
              // duration: 15 * 24 * 60 * 60 * 1000,
              end: item.endDate,
              percent: Number(item.actualProgress),
              type: "project",
              style: {
                base: {
                  fill: '#00AE9D',
                  stroke: '##C6C6C6',
                },
                'chart-row-progress-bar-pattern': {
                  // fill: 'url(#diagonalHatch)',
                  fill: 'red',
                  transform: 'translateY(0.1) scaleY(0.8)',
                },
              },
              'tree-row-bar': {
                fill: '#1EBC61',
                stroke: '#0EAC51',
              },
              'tree-row-bar-polygon': {
                stroke: '#0EAC51',
              },
              // collapsed: true,
            })
          })
          this.currentData.gantt = ganttList
          console.log(this.currentData.gantt)
        }
      })
      this.OnViewGantt('0')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    hideCctv() {
      const purpledot = document.getElementsByClassName('purpledot')
      purpledot.forEach(data => {
        const dot = data
        if (dot.classList.contains('hide')){
          dot.classList.remove('hide')
          // document.getElementsByClassName('cctv')[0].classList.remove('line-through')
        } else {
          dot.className += ' hide'
          // document.getElementsByClassName('cctv')[0].className += ' line-through'
        }
      })
    },
    hideHelmet() {
      const greendot = document.getElementsByClassName('greendot')
      greendot.forEach(data => {
        const dot = data
        if (dot.classList.contains('hide')){
          dot.classList.remove('hide')
          // document.getElementsByClassName('smartHelmet')[0].classList.remove('line-through')
        } else {
          dot.className += ' hide'
          // document.getElementsByClassName('smartHelmet')[0].className += ' line-through'
        }
      })
    },
    hideGlasses() {
      const pinkdot = document.getElementsByClassName('pinkdot')
      pinkdot.forEach(data => {
        const dot = data
        if (dot.classList.contains('hide')){
          dot.classList.remove('hide')
          // document.getElementsByClassName('smartGlasses')[0].classList.remove('line-through')
        } else {
          dot.className += ' hide'
          // document.getElementsByClassName('smartGlasses')[0].className += ' line-through'
        }
      })
    },
    // hideDrone() {
    //   const greydot = document.getElementsByClassName('greydot')
    //   greydot.forEach(data => {
    //     const dot = data
    //     if (dot.classList.contains('hide')){
    //       dot.classList.remove('hide')
    //       // document.getElementsByClassName('droneData')[0].classList.remove('line-through')
    //     } else {
    //       dot.className += ' hide'
    //       // document.getElementsByClassName('droneData')[0].className += ' line-through'
    //     }
    //   })
    // },
    apiGetAllProjects() {
      this.$axios.get(`${this.$baseUrl}/project/`).then(response => {
        if (response.status === 200) {
          const { data } = response
          const path = window.location.host
          if (this.isAerodyneDemo) {
            const aerodyneProjects = data.filter(project => project.organization == "622870d423dd7c1d1396db06")
            this.project.all = aerodyneProjects
          } else {
            const petronasProjects = data.filter(project => project.organization != "622870d423dd7c1d1396db06")
            this.project.all = petronasProjects
          }
          this.plotLocationMarker()
        }
      })
    },
    plotLocationMarker() {
      this.project.all.forEach(project => {
        const el = document.createElement('div')

        // const width = marker.properties.iconSize[0]
        // const height = marker.properties.iconSize[1]
        const iconClass = "reddot"
        el.className = `marker ${iconClass} ${project.id}`
        // el.style.backgroundImage = `url(../../../../assets/images/icons/green-dot.png)`
        // el.style.width = `${width}px`
        // el.style.height = `${height}px`
        // el.style.backgroundSize = '100%'
        // el.style.cursor = 'pointer'

        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        })

        const coordinatesArray = [project.latitude, project.longitude]

        el.addEventListener('click', () => {
          // window.alert(marker.properties.message)
          console.log('click project marker')
          console.log(project)
          // this.onClickMarkerLocation(project)
          EventBus.$emit('project-marker-clicked', project)
        })

        el.addEventListener('mouseenter', () => {
          this.map.getCanvas().style.cursor = 'pointer'

          // Copy coordinates array.
          const coordinates = coordinatesArray.slice()
          coordinates[0] += 360

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(coordinates).setHTML(project.name).addTo(this.map)
        })

        el.addEventListener('mouseleave', () => {
          this.map.getCanvas().style.cursor = ''
          popup.remove()
        })

        // Add markers to the map.
        const oneMarker = new mapboxgl.Marker(el)
          .setLngLat(coordinatesArray)
          .addTo(this.map)
          .setPopup(popup)
        this.markers.push(oneMarker)
      })

      this.map.resize()
    },
    plotLayerMarker(locationName) {
      console.log(locationName)

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      })

      this.marker01062020.features.forEach(marker => {
        const el = document.createElement('div')
        el.className = `marker ${marker.iconClass}`

        el.addEventListener('click', () => {
          // window.alert(marker.properties.message)
          console.log(marker)
          this.onItemClicked(marker)
        })

        el.addEventListener('mouseenter', () => {
          this.map.getCanvas().style.cursor = 'pointer'

          // Copy coordinates array.
          const coordinates = marker.geometry.coordinates.slice()
          coordinates[0] += 360

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(coordinates).setHTML(marker.properties.message).addTo(this.map)
        })

        el.addEventListener('mouseleave', () => {
          this.map.getCanvas().style.cursor = ''
          popup.remove()
        })

        // Add markers to the map.
        const oneMarker = new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .addTo(this.map)
          .setPopup(popup)
        this.markers.push(oneMarker)
      })
    },
    plotLayerMarker2(locationName) {
      console.log(locationName)

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      })

      this.marker03062020.features.forEach(marker => {
        const el = document.createElement('div')
        el.className = `marker ${marker.iconClass}`

        el.addEventListener('click', () => {
          // window.alert(marker.properties.message)
          this.onItemClicked(marker)
        })

        el.addEventListener('mouseenter', () => {
          this.map.getCanvas().style.cursor = 'pointer'

          // Copy coordinates array.
          const coordinates = marker.geometry.coordinates.slice()
          coordinates[0] += 360

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(coordinates).setHTML(marker.properties.message).addTo(this.map)
        })

        el.addEventListener('mouseleave', () => {
          this.map.getCanvas().style.cursor = ''
          popup.remove()
        })

        // Add markers to the map.
        const oneMarker = new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .addTo(this.map)
          .setPopup(popup)
        this.markers.push(oneMarker)
      })
    },
    plotLayerMarker3(locationName) {
      console.log(locationName)

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      })

      this.marker15062020.features.forEach(marker => {
        const el = document.createElement('div')
        el.className = `marker ${marker.iconClass}`

        el.addEventListener('click', () => {
          // window.alert(marker.properties.message)
          const type = marker.properties.message
          if (type === 'Smart Helmet') {
            EventBus.$emit('smartHelmet-clicked', true)
          } else if (type === 'RFID') {
            EventBus.$emit('rfid-clicked', true)
          } else if (type === 'Main Hall') {
            EventBus.$emit('mainhall-clicked', true)
          } else if (type === 'CCTV 1') {
            EventBus.$emit('cctv-clicked', true)
          } else if (type === 'Orthophoto') {
            // this.onItemClicked('cctv', EventBus.$emit('cctv-clicked', true))
          }
          this.onItemClicked(marker)
          // else if (type === 'Drone Data') {
          //   EventBus.$emit('droneData-clicked', true)
          // }
        })

        el.addEventListener('mouseenter', () => {
          this.map.getCanvas().style.cursor = 'pointer'

          // Copy coordinates array.
          const coordinates = marker.geometry.coordinates.slice()
          coordinates[0] += 360

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(coordinates).setHTML(marker.properties.message).addTo(this.map)
        })

        el.addEventListener('mouseleave', () => {
          this.map.getCanvas().style.cursor = ''
          popup.remove()
        })

        // Add markers to the map.
        const oneMarker = new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .addTo(this.map)
          .setPopup(popup)
        this.markers.push(oneMarker)
      })

      this.rfidSite.forEach(site => {
        console.log(site)
        this.map.addSource(site.id, site.source)

        //  Add a new layer to visualize the polygon.
        this.map.addLayer(site.draw.layer)
        // Add a black outline around the polygon.
        this.map.addLayer(site.draw.outline)

        // this.map.on('click', 'rfid-1', () => {
        //   console.log(site)
        //   EventBus.$emit('rfid-clicked', true)
        // })
      })
    },
    onClickLayerBack(value) {
      console.log(value)
      this.removeAllLayer()
      this.map.flyTo({
        center: [101.740112, 2.962541],
        zoom: 15,
      })
      this.removeMarker(this.markers)
      this.plotLocationMarker()
      if (this.map.getLayer('outline-rfid-1') != null) {
        this.map.removeLayer('rfid-1')
        this.map.removeLayer('outline-rfid-1')
        this.map.removeSource('rfid-1')
      }
      this.isSlider = false
    },
    removeAllLayer() {
      this.isOrtho = false
      this.resetOpacity()
      this.is3DModel = false
      this.isSynchro = false
      this.isPointCloud = false
      this.is2DCompare = false
      this.is3DCompare = false
      this.isComparePanel = false
      this.is3DPanel = false
      if (this.map.getLayer('sample-ortho')) {
        this.map.removeLayer('sample-ortho')
        this.map.removeSource('sample-ortho')
      }
    },
    showTimelines(val) {
      this.isTimeline = val
    },
    onClickChildLocation(value) {
      this.testAssignGanttData(this.selectedProject)
      this.isTimeline = true

      this.map.flyTo({
        center: value.coordinates,
        zoom: 17,
      })
      this.removeMarker(this.markers)
      this.value = '15.6.2020'
      this.plotLayerMarker3(value.locationName)
      this.isSlider = true
    },
    onClickMarkerLocation(project) {
      const coordinatesArray = [project.latitude, project.longitude]

      // this.testAssignGanttData(this.selectedProject)

      this.map.flyTo({
        center: coordinatesArray,
        zoom: 17,
      })
      this.removeMarker(this.markers)
      this.value = '15.6.2020'
      this.plotLayerMarker3(project.name)
      this.isSlider = true
    },
    testAssignGanttData(project) {
      let tasks = []
      console.log(tasks)
      console.log(project.tasks.length)
      if (project.tasks.length !== 0) {
        tasks = project.tasks

        this.task.all = tasks
        this.currentData.table = tasks
        const ganttList = []
        if (tasks.length !== 0) {
          this.newArea.allTask = []
          tasks.forEach(item => {
            // console.log(moment("22-10-2021", "DD-MM-YYYY").toDate().valueOf())
            this.newArea.allTask.push(item)
            ganttList.push({
              id: item.id,
              // site: ['1', '4', '5'],
              plannedStart: '23-1-2021',
              plannedEnd: '25-4-2021',
              testing: '<div style="color: blue; cursor: pointer;">Edit</div>',
              label: item.name,
              wbs: item.wbsCode,
              start: item.startDate,
              // start: 1634832000000,
              // duration: 15 * 24 * 60 * 60 * 1000,
              end: item.endDate,
              // end: 1635833000001,
              percent: Number(item.actualProgress),
              type: "project",
              style: {
                base: {
                  fill: '#00AE9D',
                  stroke: '##C6C6C6',
                },
                'chart-row-progress-bar-pattern': {
                  // fill: 'url(#diagonalHatch)',
                  fill: 'red',
                  transform: 'translateY(0.1) scaleY(0.8)',
                },
              },
              'tree-row-bar': {
                fill: '#1EBC61',
                stroke: '#0EAC51',
              },
              'tree-row-bar-polygon': {
                stroke: '#0EAC51',
              },
              // collapsed: true,
            })
          })
        }
        // this.currentData.gantt = ganttList
      }
    },
    onItemClickedSideBar(value) {
      this.onItemClicked(value)
    },
    onItemClicked(marker) {
      if (marker.properties.type === 'rfid'){
        this.map.flyTo({
          center: [marker.geometry.coordinates[0], marker.geometry.coordinates[1]],
          zoom: 18,
        })
      } else {
        this.map.flyTo({
          center: [marker.geometry.coordinates[0], marker.geometry.coordinates[1]],
          zoom: 20,
        })
      }
    },
    onItemBack() {
      this.map.flyTo({
        center: [101.73745667145212, 2.9626747164285114],
        zoom: 18,
      })
    },
    removeMarker(markers) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < markers.length; i++) {
        markers[i].remove()
      }
    },
    changeMapStyle() {
      this.isSatellite = !this.isSatellite
      if (this.isSatellite) {
        this.map.setStyle('mapbox://styles/mapbox/satellite-v9')
      } else {
        this.map.setStyle('mapbox://styles/mapbox/light-v10')
      }
    },
    openModal() {
      this.$bvModal.show("modal-table")
    },
    productivityManhours(){
      this.$bvModal.show('actual-manhours')
    },
    modalDefectIncident(type) {
      console.log(type)
      this.selectedDefectIncidentType = type
      this.$bvModal.show("modal-defect-incident")
    },
    togglePanel() {
      this.isShowPanel = !this.isShowPanel
      if (!this.isShowPanel) {
        document.getElementsByClassName('mapStyle')[0].style.left = '10px'
      } else {
        document.getElementsByClassName('mapStyle')[0].style.left = '43vh'
      }
    },
    addOrtho() {
      // this.map.addSource('sample-ortho', {
      //   type: 'image',
      //   url: 'http://54.255.194.203/ortho1.png',
      //   coordinates: [
      //     [101.651368, 2.901699],
      //     [101.653662, 2.901659],
      //     [101.653670, 2.900354],
      //     [101.651347, 2.900408],
      //   ],
      // })
      let targetLng = 0
      let targetLat = 0
      let targetOrthoPath = ""
      let targetZoom = 17
      if (!this.isAerodyneDemo) {
        targetLng = 101.740112
        targetLat = 2.962541
        targetZoom = 17
        targetOrthoPath = "https://aerodyne-gis.s3.ap-southeast-1.amazonaws.com/02_POC/16_PETRONAS_NEW_LEADERSHIP_CENTRE/20220113_PETRONAS_LEADERSHIP_CENTRE/04_DELIVERY/01_PHOTOGRAMMETRY/04_HOSTING/01_MAPTILER/01_ORTHO_MAPTILER/20220113_PETRONAS_LEADERSHIP_CENTRE/openlayers.html";
      } else {
        targetLng = 101.65184488
        targetLat = 2.9013
        targetZoom = 19
        targetOrthoPath = "https://aerodyne-mco-data.s3.ap-southeast-1.amazonaws.com/Aerodyne%20Campus/Processed/Maptiler/Ortho%20Maptiler/AerodyneCampus/openlayers.html"
      }
      this.map.flyTo({
        center: [targetLng, targetLat],
        zoom: targetZoom,
      })

      this.map.addSource('sample-ortho', {
        type: 'raster',
        tiles: [
          // 'https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg',
          //('https://aerodyne-gis.s3.ap-southeast-1.amazonaws.com/02_POC/16_PETRONAS_NEW_LEADERSHIP_CENTRE/20220113_PETRONAS_LEADERSHIP_CENTRE/04_DELIVERY/01_PHOTOGRAMMETRY/04_HOSTING/01_MAPTILER/01_ORTHO_MAPTILER/20220113_PETRONAS_LEADERSHIP_CENTRE/openlayers.html').replace(/(?=\w+\.\w{3,4}$).+/, '{z}/{x}/{y}.png'),
          (targetOrthoPath).replace(/(?=\w+\.\w{3,4}$).+/, '{z}/{x}/{y}.png'),
        ],
        tileSize: 256,
      })
      this.map.addLayer({
        id: 'sample-ortho',
        source: 'sample-ortho',
        type: 'raster',
        minzoom: 0,
        maxzoom: 22,
      })

      const slider = document.getElementById('orthoOpacity')
      this.resetOpacity()

      slider.addEventListener('input', () => {
        this.map.setPaintProperty(
          'sample-ortho',
          'raster-opacity',
          parseInt(this.orthoOpacity, 10) / 100,
        )
      })
    },
    add3Dmodel() {
      const modelOrigin = [148.9819, -35.39847]
      const modelAltitude = 0
      const modelRotate = [Math.PI / 2, 0, 0]

      const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
        modelOrigin,
        modelAltitude,
      )

      const modelTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: modelAsMercatorCoordinate.z,
        rotateX: modelRotate[0],
        rotateY: modelRotate[1],
        rotateZ: modelRotate[2],
        /* Since the 3D model is in real world meters, a scale transform needs to be
        * applied since the CustomLayerInterface expects units in MercatorCoordinates.
        */
        scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
      }

      const customLayer = {
        id: '3d-model',
        type: 'custom',
        renderingMode: '3d',
        onAdd(map, gl) {
          this.camera = new THREE.Camera()
          this.scene = new THREE.Scene()

          // create two three.js lights to illuminate the model
          const directionalLight = new THREE.DirectionalLight(0x000000)
          directionalLight.position.set(0, -70, 100).normalize()
          this.scene.add(directionalLight)

          const directionalLight2 = new THREE.DirectionalLight(0x000000)
          directionalLight2.position.set(0, 70, 100).normalize()
          this.scene.add(directionalLight2)

          // use the three.js GLTF loader to add the 3D model to the three.js scene
          // const loader = new THREE.GLTFLoader()
          // const loader = new GLTFLoader()
          // const loader = new OBJLoader()
          const MtlLoader = new MTLLoader()
          MtlLoader.load(
            // 'https://docs.mapbox.com/mapbox-gl-js/assets/34M_17/34M_17.gltf',
            // 'https://cdn.glitch.com/fcf3c007-b4eb-4250-ba6b-653fdab94ce3%2Fjapanese_temple.obj?1558792651869',
            // 'https://aerodyne-mco-data.s3.ap-southeast-1.amazonaws.com/VOPS/TEST-MESH/THAI-TEST-2/6123766927f35229a0eadd38_simplified_3d_mesh.obj',
            'https://aerodyne-mco-data.s3.ap-southeast-1.amazonaws.com/VOPS/TEST-MESH/THAI-TEST-2/6123766927f35229a0eadd38_simplified_3d_mesh.mtl',
            // gltf => {
            //   this.scene.add(gltf.scene)
            // },
            materials => {
              materials.preload()
              const objLoader = new OBJLoader()
              objLoader.setMaterials(materials)
              objLoader.load(
                'https://aerodyne-mco-data.s3.ap-southeast-1.amazonaws.com/VOPS/TEST-MESH/THAI-TEST-2/6123766927f35229a0eadd38_simplified_3d_mesh.obj',
                object => {
                  // eslint-disable-next-line no-param-reassign
                  const material = new THREE.PointsMaterial({ color: 0x000000, size: 0.25 })
                  this.mesh = new THREE.Points(object.children[0].geometry, material)
                  this.mesh.position.x = 2.5
                  this.scene.add(this.mesh)
                },
              )
            },
          )
          this.map = map

          // use the Mapbox GL JS map canvas for three.js
          this.renderer = new THREE.WebGLRenderer({
            canvas: map.getCanvas(),
            context: gl,
            antialias: true,
          })

          this.renderer.autoClear = false
        },
        render(gl, matrix) {
          const rotationX = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(1, 0, 0),
            modelTransform.rotateX,
          )
          const rotationY = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 1, 0),
            modelTransform.rotateY,
          )
          const rotationZ = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 0, 1),
            modelTransform.rotateZ,
          )

          const m = new THREE.Matrix4().fromArray(matrix)
          const l = new THREE.Matrix4()
            .makeTranslation(
              modelTransform.translateX,
              modelTransform.translateY,
              modelTransform.translateZ,
            )
            .scale(
              new THREE.Vector3(
                modelTransform.scale,
                -modelTransform.scale,
                modelTransform.scale,
              ),
            )
            .multiply(rotationX)
            .multiply(rotationY)
            .multiply(rotationZ)

          this.camera.projectionMatrix = m.multiply(l)
          this.renderer.resetState()
          this.renderer.render(this.scene, this.camera)
          this.map.triggerRepaint()
        },
      }

      this.map.addLayer(customLayer, 'waterway-label')
    },
    toggleOrtho() {
      if (this.map.getLayer('sample-ortho')) {
        this.map.removeLayer('sample-ortho')
        this.map.removeSource('sample-ortho')
      } else {
        this.addOrtho()
      }
    },
    toggle3DModel() {
      if (this.map.getLayer('3d-model')) {
        // this.map.removeLayer('3d-model')
        this.map.setLayoutProperty('3d-model', 'visibility', 'none')
      } else {
        this.map.setLayoutProperty('3d-model', 'visibility', 'visible')
        this.map.flyTo({
          center: [148.9819, -35.39847],
          zoom: 18,
          pitch: 60,
        })
        this.add3Dmodel()
      }
    },
    toggleBottomRightPanel(type) {
      if (type === 'layer') {
        this.isComparePanel = false
        this.is3DPanel = !this.is3DPanel
      } else {
        this.is3DPanel = false
        this.isComparePanel = !this.isComparePanel
      }
    },
    resetOpacity() {
      this.orthoOpacity = 100
    },
    toggleCompare(type) {
      if (type === '2d') {
        this.is2DCompare = !this.is2DCompare
        this.is3DCompare = false
        if (this.is2DCompare === false && this.is3DCompare === false){
          this.isTimeline = true
        } else {
          this.isTimeline = false
        }
      } else if (type === '3d'){
        this.is3DCompare = !this.is3DCompare
        this.is2DCompare = false
        if (this.is2DCompare === false && this.is3DCompare === false){
          this.isTimeline = true
        } else {
          this.isTimeline = false
        }
      }

      this.isOrtho = false
      this.resetOpacity()
      this.is3DModel = false
      this.isSynchro = false
      this.isPointCloud = false
      if (this.map.getLayer('sample-ortho')) {
        this.map.removeLayer('sample-ortho')
        this.map.removeSource('sample-ortho')
      }
    },
    toggleSwitch(layer) {
      this.is3DCompare = false
      this.is2DCompare = false
      if (layer === 'ortho') {
        this.toggleOrtho()
      }
      if (layer === '3d') {
        if (this.is3DModel) {
          this.is3DModel = true
          this.isPointCloud = false
          this.isSynchro = false
          this.isTimeline = false
        } else {
          this.isTimeline = true
        }
      }
      if (layer === 'synchro') {
        if (this.isSynchro) {
          this.isSynchro = true
          this.isPointCloud = false
          this.is3DModel = false
          this.isTimeline = false
          this.togglePanel()
        } else {
          this.isTimeline = true
          this.togglePanel()
        }
      }
      if (layer === 'pointcloud') {
        if (this.isPointCloud) {
          this.is3DModel = false
          this.isSynchro = false
          this.isPointCloud = true
          this.isTimeline = false
        } else {
          this.isTimeline = true
        }
      }
    },
    switchToggled(value) {
      console.log(value)

      if (value.layer === 'ortho') {
        this.isOrtho = value.value
        if (!this.isOrtho) {
          this.resetOpacity()
        }
      } else if (value.layer === '3d') {
        this.is3DModel = value.value
      } else if (value.layer === 'synchro') {
        this.isSynchro = value.value
      } else {
        this.isPointCloud = value.value
      }

      this.toggleSwitch(value.layer)
    },
    testVisibility(){
      this.mapGantt.setLayoutProperty('polygon-1', 'visibility', 'none')
    },
    getAllZones(value) {
      console.log('get all zones')
      console.log(value)
      if (value !== '0') {
        this.$axios.get(`${this.$baseUrl}/tasks/${value}`).then(response => {
          if (response.status === 200 || response.status === 201) {
            const { data } = response
            console.log('getting zone by task id')
            const { zones } = data

            if (zones.length !== 0) {
              zones.forEach(zone => {
                const coordinate = zone.points
                const source = {
                  type: 'geojson',
                  data: {
                    type: 'FeatureCollection',
                    features: [
                      {
                        type: 'Feature',
                        properties: {
                        // site: 'Engineering Task 3',
                        // site: site.name,
                          zone: zone.name,
                          id: `polygon-${zone.id}`,
                          zoneId: zone.id,
                        },
                        geometry: {
                          type: 'Polygon',
                          coordinates: [
                            coordinate,
                          ],
                        // coordinates: coordinate,
                        },
                      },
                    ],
                  },
                }
                this.mapGantt.addSource(`polygon-${zone.id}`, source)

                const draw = {
                  layer: {
                    id: `polygon-${zone.id}`,
                    type: 'fill',
                    source: `polygon-${zone.id}`, // reference the data source
                    layout: {
                      visibility: 'visible',
                    },
                    paint: {
                      'fill-color': '#68CEE5', // blue color fill
                      'fill-opacity': 0.2,
                    },
                  },
                  outline: {
                    id: `outline-${zone.id}`,
                    type: 'line',
                    source: `polygon-${zone.id}`,
                    layout: {
                      visibility: 'visible',
                    },
                    paint: {
                      'line-color': '#43C1DD',
                      'line-width': 2,
                    },
                  },
                  hover: {
                    coordinate: [101.739332, 2.963484],
                  },
                }

                //  Add a new layer to visualize the polygon.
                this.mapGantt.addLayer(draw.layer)
                // Add a black outline around the polygon.
                this.mapGantt.addLayer(draw.outline)

                const popup = new mapboxgl.Popup({
                  closeButton: false,
                  closeOnClick: true,
                })

                this.mapGantt.on('contextmenu', `polygon-${zone.id}`, siteData => {
                  const mapop = document.getElementsByClassName('mapboxgl-popup')
                  console.log('zone clicked')
                  if (mapop.length) {
                    mapop[0].remove()
                  }
                  popup.setLngLat(siteData.lngLat)
                    .setHTML(`<div id="editZoneBtn" data-id="${zone.id}" class="pointer" style="padding: 6px;"><i class="fa fa-pencil-alt" style="margin-right: 10px;"></i>Edit Zone</div><div id="deleteZoneBtn2" data-id="${zone.id}" class="pointer" style="padding: 6px;"><i class="fa fa-trash" style="margin-right: 10px;"></i>Delete</div>`)
                    .addTo(this.mapGantt)

                  const editZoneBtn = document.getElementById('editZoneBtn')
                  editZoneBtn.addEventListener('click', () => {
                    const id = editZoneBtn.getAttribute('data-id')
                    console.log('edit zone clicked')
                    console.log(id)
                    this.editZoneId = id
                    this.showModalEdit = true
                  })

                  const deleteZoneBtn = document.getElementById('deleteZoneBtn')
                  deleteZoneBtn.addEventListener('click', () => {
                    const id = deleteZoneBtn.getAttribute('data-id')
                    console.log('delete zone clicked')
                    console.log(id)
                    this.deleteZone(id)
                  })
                })

                this.mapGantt.on('click', `polygon-${zone.id}`, siteData => {
                  console.log('zone-clicked')
                  new mapboxgl.Popup()
                    .setLngLat(siteData.lngLat)
                    .setHTML(`<div style="" class="bg-white d-flex flex-column" ><div class="d-flex"><span>${siteData.features[0].properties.zone}</span> </div> <div class="d-flex"> <div class="col-sm-7 font-weight-bold" > </div></div> <div class="d-flex"> <div class="col-sm-7 font-weight-bold" ></div> </div>`)
                    .addTo(this.mapGantt)
                })
              })
            }
          }
        })
      } else {
        this.$axios.get(`${this.$baseUrl}/zones`).then(response => {
          if (response.status === 200 || response.status === 201) {
            const { data } = response
            this.allZoneList = data
            this.allZoneListId = []
            const tempListId = []
            const tempList = []

            this.allZoneList.forEach(zoneList => {
              this.allZoneListId.push(zoneList.id)
            })

            this.$axios.get(`${this.$baseUrl}/zones/getZoneByProject/${this.selectedProject.id}`).then(response2 => {
              if (response2.status === 200 || response2.status === 201) {
                const data2 = response2.data

                data2.forEach(temp => {
                  if (!tempListId.includes(temp.id)) {
                    tempListId.push(temp.id)
                    tempList.push(temp)
                  }
                })
                console.log(data2.length)
                if (data2.length === 0) {
                  this.allZoneList.forEach(zone => {
                    const coordinate = zone.points
                    const source = {
                      type: 'geojson',
                      data: {
                        type: 'FeatureCollection',
                        features: [
                          {
                            type: 'Feature',
                            properties: {
                            // site: 'Engineering Task 3',
                            // site: site.name,
                              zone: zone.name,
                              id: `polygon-empty-${zone.id}`,
                              zoneId: zone.id,
                            },
                            geometry: {
                              type: 'Polygon',
                              coordinates: [
                                coordinate,
                              ],
                            // coordinates: coordinate,
                            },
                          },
                        ],
                      },
                    }
                    this.mapGantt.addSource(`polygon-empty-${zone.id}`, source)

                    const draw2 = {
                      layer: {
                        id: `polygon-empty-${zone.id}`,
                        type: 'fill',
                        source: `polygon-empty-${zone.id}`, // reference the data source
                        layout: {
                          visibility: 'visible',
                        },
                        paint: {
                          'fill-color': '#68CEE5', // blue color fill
                          'fill-opacity': 0.2,
                        },
                      },
                      outline: {
                        id: `outline-empty-${zone.id}`,
                        type: 'line',
                        source: `polygon-empty-${zone.id}`,
                        layout: {
                          visibility: 'visible',
                        },
                        paint: {
                          'line-color': '#43C1DD',
                          'line-width': 2,
                        },
                      },
                      hover: {
                        coordinate: [101.739332, 2.963484],
                      },
                    }

                    //  Add a new layer to visualize the polygon.
                    this.mapGantt.addLayer(draw2.layer)
                    // Add a black outline around the polygon.
                    this.mapGantt.addLayer(draw2.outline)

                    const popup2 = new mapboxgl.Popup({
                      closeButton: false,
                      closeOnClick: true,
                    })

                    this.mapGantt.on('contextmenu', `polygon-empty-${zone.id}`, siteData => {
                      const mapop = document.getElementsByClassName('mapboxgl-popup')
                      if (mapop.length) {
                        mapop[0].remove()
                      }
                      popup2.setLngLat(siteData.lngLat)
                        .setHTML(`<div id="editZoneBtn2" data-id="${zone.id}" class="pointer" style="padding: 6px;"><i class="fa fa-pencil-alt" style="margin-right: 10px;"></i>Edit Zone</div><div id="deleteZoneBtn2" data-id="${zone.id}" class="pointer" style="padding: 6px;"><i class="fa fa-trash" style="margin-right: 10px;"></i>Delete</div>`)
                        .addTo(this.mapGantt)

                      const editZoneBtn2 = document.getElementById('editZoneBtn2')
                      editZoneBtn2.addEventListener('click', () => {
                        const id = editZoneBtn2.getAttribute('data-id')
                        // console.log('edit zone clicked')
                        // console.log(id)
                        this.editZoneId = id
                        this.showModalEdit = true
                      })

                      const deleteZoneBtn2 = document.getElementById('deleteZoneBtn2')
                      deleteZoneBtn2.addEventListener('click', () => {
                        const id = deleteZoneBtn2.getAttribute('data-id')
                        // console.log('delete zone clicked')
                        // console.log(id)
                        this.deleteZone(id)
                      })
                    })

                    this.mapGantt.on('click', `polygon-empty-${zone.id}`, siteData => {
                      new mapboxgl.Popup()
                        .setLngLat(siteData.lngLat)
                        .setHTML(`<div style="" class="bg-white d-flex flex-column" ><div class="d-flex"><span>${siteData.features[0].properties.zone}</span> </div> <div class="d-flex"> <div class="col-sm-7 font-weight-bold" > </div></div> <div class="d-flex"> <div class="col-sm-7 font-weight-bold" ></div> </div>`)
                        .addTo(this.mapGantt)
                      this.isSpecific = false

                      this.currentData.table = []
                      const ganttListSpesific = []
                      // console.log(this.task.all)
                      this.task.all.forEach(taskZone => {
                        this.currentData.table.push(taskZone.task)
                        ganttListSpesific.push({
                          id: taskZone.id,
                          label: taskZone.name,
                          wbs: taskZone.wbsCode,
                          start: taskZone.startDate,
                          // duration: 15 * 24 * 60 * 60 * 1000,
                          end: taskZone.endDate,
                          percent: Number(taskZone.actualProgress),
                          type: "project",
                          style: {
                            base: {
                              fill: '#00AE9D',
                              stroke: '##C6C6C6',
                            },
                            'chart-row-progress-bar-pattern': {
                            // fill: 'url(#diagonalHatch)',
                              fill: 'red',
                              transform: 'translateY(0.1) scaleY(0.8)',
                            },
                          },
                          'tree-row-bar': {
                            fill: '#1EBC61',
                            stroke: '#0EAC51',
                          },
                          'tree-row-bar-polygon': {
                            stroke: '#0EAC51',
                          },
                        // collapsed: true,
                        })
                      })
                      console.log(ganttListSpesific)
                      this.currentData.gantt = ganttListSpesific
                    })
                  })
                } else {
                  tempList.forEach(zone => {
                    for (let i = 0; i < this.allZoneListId.length; i += 1) {
                      if (this.allZoneListId[i] === zone.id) {
                        this.allZoneListId.splice(i, 1)
                      }
                    }
                    const coordinate = zone.points
                    const source = {
                      type: 'geojson',
                      data: {
                        type: 'FeatureCollection',
                        features: [
                          {
                            type: 'Feature',
                            properties: {
                            // site: 'Engineering Task 3',
                            // site: site.name,
                              zone: zone.name,
                              id: `${zone.id}`,
                              zoneId: zone.id,
                            },
                            geometry: {
                              type: 'Polygon',
                              coordinates: [
                                coordinate,
                              ],
                            // coordinates: coordinate,
                            },
                          },
                        ],
                      },
                    }
                    console.log(zone.id)
                    this.mapGantt.addSource(`polygon-${zone.id}`, source)

                    const draw2 = {
                      layer: {
                        id: `layer-${zone.id}`,
                        type: 'fill',
                        source: `polygon-${zone.id}`, // reference the data source
                        layout: {
                          visibility: 'visible',
                        },
                        paint: {
                          'fill-color': '#68CEE5', // blue color fill
                          'fill-opacity': 0.2,
                        },
                      },
                      outline: {
                        id: `outline-${zone.id}`,
                        type: 'line',
                        source: `polygon-${zone.id}`,
                        layout: {
                          visibility: 'visible',
                        },
                        paint: {
                          'line-color': '#43C1DD',
                          'line-width': 2,
                        },
                      },
                      hover: {
                        coordinate: [101.739332, 2.963484],
                      },
                    }

                    //  Add a new layer to visualize the polygon.
                    this.mapGantt.addLayer(draw2.layer)
                    // Add a black outline around the polygon.
                    this.mapGantt.addLayer(draw2.outline)

                    const popup2 = new mapboxgl.Popup({
                      closeButton: false,
                      closeOnClick: true,
                    })

                    this.mapGantt.on('contextmenu', `layer-${zone.id}`, siteData => {
                      const mapop = document.getElementsByClassName('mapboxgl-popup')
                      if (mapop.length) {
                        mapop[0].remove()
                      }
                      popup2.setLngLat(siteData.lngLat)
                        .setHTML(`<div id="editZoneBtn" data-id="${zone.id}" class="pointer" style="padding: 6px;"><i class="fa fa-pencil-alt" style="margin-right: 10px;"></i>Edit Zone</div><div id="deleteZoneBtn" data-id="${zone.id}" class="pointer" style="padding: 6px;"><i class="fa fa-trash" style="margin-right: 10px;"></i>Delete</div>`)
                        .addTo(this.mapGantt)

                      const editZoneBtn = document.getElementById('editZoneBtn')
                      editZoneBtn.addEventListener('click', () => {
                        const id = editZoneBtn.getAttribute('data-id')
                        // console.log('edit zone clicked')
                        // console.log(id)
                        this.editZoneId = id
                        this.showModalEdit = true
                      })

                      const deleteZoneBtn = document.getElementById('deleteZoneBtn')
                      deleteZoneBtn.addEventListener('click', () => {
                        const id = deleteZoneBtn.getAttribute('data-id')
                        // console.log('delete zone clicked')
                        // console.log(id)
                        this.deleteZone(id)
                      })
                    })

                    this.mapGantt.on('click', `layer-${zone.id}`, siteData => {
                      new mapboxgl.Popup()
                        .setLngLat(siteData.lngLat)
                        .setHTML(`<div style="" class="bg-white d-flex flex-column" ><div class="d-flex"><span>${siteData.features[0].properties.zone}</span> </div> <div class="d-flex"> <div class="col-sm-7 font-weight-bold" > </div></div> <div class="d-flex"> <div class="col-sm-7 font-weight-bold" ></div> </div>`)
                        .addTo(this.mapGantt)
                      // if (value === '0') {
                      //   this.isSpecific = false
                      // } else {
                      //   this.isSpecific = true
                      // }
                      this.isSpecific = true

                      this.currentData.table = []
                      const ganttListSpesific = []
                      console.log(this.taskByZones)
                      this.taskByZones.forEach(taskZone => {
                        if (taskZone.zone.id === siteData.features[0].properties.zoneId) {
                          this.currentData.table.push(taskZone.task)
                          ganttListSpesific.push({
                            id: taskZone.task.id,
                            label: taskZone.task.name,
                            wbs: taskZone.task.wbsCode,
                            start: taskZone.task.startDate,
                            // duration: 15 * 24 * 60 * 60 * 1000,
                            end: taskZone.task.endDate,
                            percent: Number(taskZone.task.actualProgress),
                            type: "project",
                            style: {
                              base: {
                                fill: '#00AE9D',
                                stroke: '##C6C6C6',
                              },
                              'chart-row-progress-bar-pattern': {
                              // fill: 'url(#diagonalHatch)',
                                fill: 'red',
                                transform: 'translateY(0.1) scaleY(0.8)',
                              },
                            },
                            'tree-row-bar': {
                              fill: '#1EBC61',
                              stroke: '#0EAC51',
                            },
                            'tree-row-bar-polygon': {
                              stroke: '#0EAC51',
                            },
                          // collapsed: true,
                          })
                          this.currentData.gantt = ganttListSpesific
                          console.log(this.currentData.gantt)
                        }
                      })
                    })
                  })
                  console.log(this.allZoneList)
                  this.allZoneList.forEach(unAssignZone => {
                    // console.log(this.allZoneListId.includes(unAssignZone.id))
                    if (this.allZoneListId.includes(unAssignZone.id)) {
                      const coordinate = unAssignZone.points
                      const source = {
                        type: 'geojson',
                        data: {
                          type: 'FeatureCollection',
                          features: [
                            {
                              type: 'Feature',
                              properties: {
                                // site: 'Engineering Task 3',
                                // site: site.name,
                                zone: unAssignZone.name,
                                id: `${unAssignZone.id}`,
                                zoneId: unAssignZone.id,
                              },
                              geometry: {
                                type: 'Polygon',
                                coordinates: [
                                  coordinate,
                                ],
                                // coordinates: coordinate,
                              },
                            },
                          ],
                        },
                      }
                      // console.log(unAssignZone.id)
                      this.mapGantt.addSource(`polygon-${unAssignZone.id}`, source)

                      const draw2 = {
                        layer: {
                          id: `layer-${unAssignZone.id}`,
                          type: 'fill',
                          source: `polygon-${unAssignZone.id}`, // reference the data source
                          layout: {
                            visibility: 'visible',
                          },
                          paint: {
                            'fill-color': '#68CEE5', // blue color fill
                            'fill-opacity': 0.2,
                          },
                        },
                        outline: {
                          id: `outline-${unAssignZone.id}`,
                          type: 'line',
                          source: `polygon-${unAssignZone.id}`,
                          layout: {
                            visibility: 'visible',
                          },
                          paint: {
                            'line-color': '#43C1DD',
                            'line-width': 2,
                          },
                        },
                        hover: {
                          coordinate: [101.739332, 2.963484],
                        },
                      }

                      //  Add a new layer to visualize the polygon.
                      this.mapGantt.addLayer(draw2.layer)
                      // Add a black outline around the polygon.
                      this.mapGantt.addLayer(draw2.outline)

                      const popup2 = new mapboxgl.Popup({
                        closeButton: false,
                        closeOnClick: true,
                      })

                      this.mapGantt.on('contextmenu', `layer-${unAssignZone.id}`, siteData => {
                        // console.log('no task clicked')
                        const mapop = document.getElementsByClassName('mapboxgl-popup')
                        if (mapop.length) {
                          mapop[0].remove()
                        }
                        popup2.setLngLat(siteData.lngLat)
                          .setHTML(`<div id="editZoneBtn" data-id="${unAssignZone.id}" class="pointer" style="padding: 6px;"><i class="fa fa-pencil-alt" style="margin-right: 10px;"></i>Edit Zone</div><div id="deleteZoneBtn" data-id="${unAssignZone.id}" class="pointer" style="padding: 6px;"><i class="fa fa-trash" style="margin-right: 10px;"></i>Delete</div>`)
                          .addTo(this.mapGantt)

                        const editZoneBtn = document.getElementById('editZoneBtn')
                        editZoneBtn.addEventListener('click', () => {
                          const id = editZoneBtn.getAttribute('data-id')
                          // console.log('edit zone clicked')
                          // console.log(id)
                          this.editZoneId = id
                          this.showModalEdit = true
                        })

                        const deleteZoneBtn = document.getElementById('deleteZoneBtn')
                        deleteZoneBtn.addEventListener('click', () => {
                          const id = deleteZoneBtn.getAttribute('data-id')
                          // console.log('delete zone clicked')
                          // console.log(id)
                          this.deleteZone(id)
                        })
                      })

                      this.mapGantt.on('click', `layer-${unAssignZone.id}`, siteData => {
                        console.log('atau click dekat sini')
                        console.log(unAssignZone.id)
                        new mapboxgl.Popup()
                          .setLngLat(siteData.lngLat)
                          .setHTML(`<div style="" class="bg-white d-flex flex-column" ><div class="d-flex"><span>${siteData.features[0].properties.zone}</span> </div> <div class="d-flex"> <div class="col-sm-7 font-weight-bold" > </div></div> <div class="d-flex"> <div class="col-sm-7 font-weight-bold" ></div> </div>`)
                          .addTo(this.mapGantt)

                        this.isSpecific = false

                        this.currentData.table = []
                        const ganttListSpesific = []

                        this.task.all.forEach(taskZone => {
                          this.currentData.table.push(taskZone.task)
                          ganttListSpesific.push({
                            id: taskZone.id,
                            label: taskZone.name,
                            wbs: taskZone.wbsCode,
                            start: taskZone.startDate,
                            // duration: 15 * 24 * 60 * 60 * 1000,
                            end: taskZone.endDate,
                            percent: Number(taskZone.actualProgress),
                            type: "project",
                            style: {
                              base: {
                                fill: '#00AE9D',
                                stroke: '##C6C6C6',
                              },
                              'chart-row-progress-bar-pattern': {
                              // fill: 'url(#diagonalHatch)',
                                fill: 'red',
                                transform: 'translateY(0.1) scaleY(0.8)',
                              },
                            },
                            'tree-row-bar': {
                              fill: '#1EBC61',
                              stroke: '#0EAC51',
                            },
                            'tree-row-bar-polygon': {
                              stroke: '#0EAC51',
                            },
                          // collapsed: true,
                          })
                        })

                        // ganttListSpesific.forEach(val => {
                        //   if (unAssignZone.id !== val.id) {
                        //     this.noData = true
                        //     console.log('takde task')
                        //   } else {
                        //     console.log('ada task ke tak')
                        //     this.noData = false
                        //   }
                        // })
                        this.currentData.gantt = ganttListSpesific
                        this.noData = true
                        // console.log(this.currentData.gantt)
                      })
                    } else {
                      this.mapGantt.on('click', `layer-${unAssignZone.id}`, siteData => {
                        console.log('on click sini')
                        console.log(unAssignZone.id)
                        this.noData = false
                        new mapboxgl.Popup()
                          .setLngLat(siteData.lngLat)
                          .setHTML(`<div style="" class="bg-white d-flex flex-column" ><div class="d-flex"><span>${siteData.features[0].properties.zone}</span> </div> <div class="d-flex"> <div class="col-sm-7 font-weight-bold" > </div></div> <div class="d-flex"> <div class="col-sm-7 font-weight-bold" ></div> </div>`)
                          .addTo(this.mapGantt)
                      })
                      console.log('ke masuk sini do')
                    }
                  })
                }
              }
            })
          }
        })
      }
    },
    OnViewGantt(value) {
      this.isGantt = true

      let targetLng = 0
      let targetLat = 0
      let targetOrthoPath = ""
      let targetZoom = 17
      if (!this.isAerodyneDemo) {
        targetLng = 101.740112
        targetLat = 2.962541
        targetZoom = 17
        targetOrthoPath = "https://aerodyne-gis.s3.ap-southeast-1.amazonaws.com/02_POC/16_PETRONAS_NEW_LEADERSHIP_CENTRE/20220113_PETRONAS_LEADERSHIP_CENTRE/04_DELIVERY/01_PHOTOGRAMMETRY/04_HOSTING/01_MAPTILER/01_ORTHO_MAPTILER/20220113_PETRONAS_LEADERSHIP_CENTRE/openlayers.html";
      } else {
        targetLng = 101.65184488
        targetLat = 2.9013
        targetZoom = 19
        targetOrthoPath = "https://aerodyne-mco-data.s3.ap-southeast-1.amazonaws.com/Aerodyne%20Campus/Processed/Maptiler/Ortho%20Maptiler/AerodyneCampus/openlayers.html"
      }

      this.mapGantt = new mapboxgl.Map({
        container: 'mapContainerGantt', // container ID
        style: 'mapbox://styles/mapbox/light-v10', // style URL
        center: [targetLng, targetLat],
        zoom: 18,
        antialias: true,
      })

      this.mapGantt.on('load', () => {
        this.mapGantt.resize()

        this.mapGantt.addSource('sample-ortho', {
          type: 'raster',
          tiles: [
            // 'https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg',
            (targetOrthoPath).replace(/(?=\w+\.\w{3,4}$).+/, '{z}/{x}/{y}.png'),
          ],
          tileSize: 256,
        })
        this.mapGantt.addLayer({
          id: 'sample-ortho',
          source: 'sample-ortho',
          type: 'raster',
          minzoom: 0,
          maxzoom: 22,
        })

        const slider = document.getElementById('orthoOpacity')
        this.resetOpacity()

        slider.addEventListener('input', () => {
          this.mapGantt.setPaintProperty(
            'sample-ortho',
            'raster-opacity',
            parseInt(this.orthoOpacity, 10) / 100,
          )
        })

        // const ganttList = []
        this.taskByZones = []
        this.currentZoneIdList = []
        this.newArea.allTask = []
        // console.log('valueeee')
        // console.log(value)
        // console.log(`ini ${this.task.all.length}`)
        // console.log(this.task.all)
        if (this.task.all.length !== 0){
          this.task.all.forEach(task => {
            this.$axios.get(`${this.$baseUrl}/tasks/${task.id}`).then(response => {
              if (response.status === 200 || response.status === 201) {
                const { data } = response
                // console.log(data)
                data.zones.forEach(zone => {
                  this.taskByZones.push({
                    zone,
                    task,
                  })
                })
              }
            })
          })
          this.getAllZones(value)
        }

        // this.task.all.forEach(task => {
        //   this.$axios.get(`${this.$baseUrl}/tasks/${task.id}`).then(response => {
        //     if (response.status === 200 || response.status === 201) {
        //       const { data } = response
        //       console.log(data)
        //     }
        //   })

        //   ganttList.push({
        //     id: task.id,
        //     plannedStart: '23-1-2021',
        //     plannedEnd: '25-4-2021',
        //     label: task.name,
        //     testing: '<div style="color: blue; cursor: pointer;">Edit</div>',
        //     wbs: task.wbsCode,
        //     start: task.startDate,
        //     // duration: 15 * 24 * 60 * 60 * 1000,
        //     end: task.endDate,
        //     percent: Number(task.actualProgress),
        //     type: "project",
        //     style: {
        //       base: {
        //         fill: '#00AE9D',
        //         stroke: '##C6C6C6',
        //       },
        //       'chart-row-progress-bar-pattern': {
        //         // fill: 'url(#diagonalHatch)',
        //         fill: 'red',
        //         transform: 'translateY(0.1) scaleY(0.8)',
        //       },
        //     },
        //     'tree-row-bar': {
        //       fill: '#1EBC61',
        //       stroke: '#0EAC51',
        //     },
        //     'tree-row-bar-polygon': {
        //       stroke: '#0EAC51',
        //     },
        //     // collapsed: true,
        //   })

        //   this.currentData.table = this.task.all // kat siniiiiii
        //   this.currentData.gantt = ganttList
        // })

        // this.currentData.table = this.task.all
        // this.currentData.gantt = ganttList
      })

      const draw = new MapboxDraw({
        displayControlsDefault: false,
        // Select which mapbox-gl-draw control buttons to add to the map.
        controls: {
          polygon: true,
          trash: false,
        },
        // Set mapbox-gl-draw to draw by default.
        // The user does not have to click the polygon control button first.
        // defaultMode: 'draw_polygon',
      })
      this.mapGantt.addControl(draw)

      this.mapGantt.on('draw.create', this.updateArea)
      this.mapGantt.on('draw.update', this.updateArea)
      this.map.resize()
    },
    onHistoryClick(value) {
      console.log(value)
      this.isGantt = true
      this.mapGantt = new mapboxgl.Map({
        container: 'mapContainerGantt', // container ID
        style: 'mapbox://styles/mapbox/light-v10', // style URL
        center: [101.740112, 2.962541],
        zoom: 18,
        antialias: true,
      })

      this.mapGantt.on('load', () => {
        this.mapGantt.resize()
        this.petronasSite.forEach((site, index) => {
          // console.log(site)
          this.mapGantt.addSource(site.id, site.source)

          //  Add a new layer to visualize the polygon.
          this.mapGantt.addLayer(site.draw.layer)
          // Add a black outline around the polygon.
          this.mapGantt.addLayer(site.draw.outline)

          this.mapGantt.on('click', site.id, data => {
            new mapboxgl.Popup()
              .setLngLat(data.lngLat)
              .setHTML(`<div style="" class="bg-white d-flex flex-column" > <span class="pt-1 pl-1 font-weight-bold">ETI Inspection 2021</span> <hr style="border: 1px solid #EBE9F1; width: 100%;" > <div class="d-flex"> <div class="col-sm-7 font-weight-bold" > <span>Location:</span> </div> <span>${data.features[0].properties.site}</span> </div> <div class="d-flex"> <div class="col-sm-7 font-weight-bold" > <span>Planned Start:</span> </div> <span>01.01.2021 00:00:00</span> </div> <div class="d-flex"> <div class="col-sm-7 font-weight-bold" > <span>Planned End:</span> </div> <span>16.01.2021 00:00:00</span> </div> </div>`)
              .addTo(this.mapGantt)
            this.isSpecific = true
            this.currentData.gantt = this.gantt[site.id]
            this.currentData.table = this.table[site.id]
            // this.currentTitle = data.features[0].properties.site.replace(/([A-Z])/g, ' $1').trim()
            this.currentTitle = data.features[0].properties.site.replace(/([A-Z])/g, ' $1').trim()
            this.hideShowPolygon(site.id, index.toString())
          })
        })

        this.map.resize()

        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        })

        this.petronasSite.forEach(site => {
          this.mapGantt.on('mouseenter', site.id, () => {
            this.mapGantt.getCanvas().style.cursor = 'pointer'

            const coordinates = site.hover.coordinate.slice()
            coordinates[0] += 360

            // const { currentSite } = e.features[0].properties
            const currentSite = site.source.data.features[0].properties.site

            popup.setLngLat(coordinates).setHTML(currentSite).addTo(this.mapGantt)
          })

          this.mapGantt.on('mouseleave', site.id, () => {
            this.mapGantt.getCanvas().style.cursor = ''
            popup.remove()
          })
        })

        const draw = new MapboxDraw({
          displayControlsDefault: false,
          // Select which mapbox-gl-draw control buttons to add to the map.
          controls: {
            polygon: true,
            trash: false,
          },
          // Set mapbox-gl-draw to draw by default.
          // The user does not have to click the polygon control button first.
          // defaultMode: 'draw_polygon',
        })
        this.mapGantt.addControl(draw)

        this.mapGantt.on('draw.create', this.updateArea)
        this.mapGantt.on('draw.update', this.updateArea)
      })
    },

    // eslint-disable-next-line no-unused-vars
    hideShowPolygon(id, id2){
      this.petronasSite.forEach((site, index) => {
        if (site.id !== id){
          this.mapGantt.setLayoutProperty(site.id, 'visibility', 'none')
          this.mapGantt.setLayoutProperty(`outline-${(index + 1).toString()}`, 'visibility', 'none')
        }
      })
    },
    onClickTaskName(taskId){
      // this.isSpecific = true
      // eslint-disable-next-line no-unused-vars
      this.OnViewGantt(taskId)
    },
    onClickAction(taskId) {
      console.log(taskId)
      this.editTaskId = taskId
      this.showEditTaskModal = true
    },
    updateArea(e) {
      // console.log(e)
      if (e.type === 'draw.create') {
        const coordinates = e.features[0].geometry.coordinates[0]
        this.createdCoordinates = coordinates
        this.editZoneId = '0'
        this.showModal = true
        // this.$bvModal.show("task-modal")
      }
    },
    onBack() {
      this.isGantt = false
      this.removeAllLayer()
      this.map.remove()
      this.map = new mapboxgl.Map({
        container: 'mapContainer', // container ID
        style: 'mapbox://styles/mapbox/light-v10', // style URL
        center: [101.740112, 2.962541],
        zoom: 17,
        antialias: true,
      })

      this.map.on('load', () => {
        this.map.resize()
        // this.plotLocationMarker()
        this.plotLayerMarker3('somewhere')
      })
    },
    sliderChange() {
      this.removeMarker(this.markers)
      if (this.map.getLayer('outline-rfid-1') != null) {
        this.isHideRfid = true
        this.map.removeLayer('rfid-1')
        this.map.removeLayer('outline-rfid-1')
        this.map.removeSource('rfid-1')
        console.log('if pertama')
      }
      if (this.value === '1.6.2020') {
        this.plotLayerMarker('somewhere')
        console.log('if dua')
        this.isHideOthers = true
      } else if (this.value === '3.6.2020') {
        this.plotLayerMarker2('somewhere')
        this.isHideOthers = false
        console.log('if tiga')
      } else if (this.value === '15.6.2020') {
        this.plotLayerMarker3('somewhere')
        this.isHideRfid = false
        console.log('if empat')
      }
      console.log(this.isHideRfid)
    },
    deleteZone(id) {
      this.$axios.delete(`${this.$baseUrl}/zones/${id}`).then(response => {
        console.log('delete zone')
        console.log(response)
        if (response.status === 200 || response.status === 201) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Info`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Successfully Deleted!`,
            },
          })
          this.OnViewGantt('0')
        }
      })
    },
  },
}
</script>
